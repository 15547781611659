import AbstractView from 'core/abstracts/AbstractView';
import { gsap } from 'gsap';
import { Observer } from 'gsap/Observer';
import Slider from 'Slider';

export default class extends AbstractView {

	/**
	 * @type {HTMLElement}
	 */
	#$videosWrapper;
	/**
	 * @type {NodeList}
	 */
	#$videos;
	/**
	 * @type {AudioContext}
	 */
	#audio;
	/**
	 * @type {NodeList}
	 */
	#$players;
	/**
	 * @type {HTMLElement}
	 */
	#$buttonNext;
	/**
	 * @type {HTMLElement}
	 */
	#$buttonVolume;
	/**
	 * @type {NodeList}
	 */
	#$titles;
	/**
	 * @type {NodeList}
	 */
	#$counters;
	/**
	 * @type {Object}
	 */
	#videosSlider;
	/**
	 * @type {Object}
	 */
	#titlesSlider;
	/**
	 * @type {Object}
	 */
	#countersSlider;
	/**
	 * @type {Number}
	 */
	#currentIndex = 0;
	/**
	 * @type {Number}
	 */
	#volume = false;
	/**
	 * @type {SplitText}
	 */
	#splitTitles = false;

	constructor( audio ) {
		super();
		this.#audio = audio;
		this.init();
	}

	initDOM() {
		super.initDOM();

		this.#$videosWrapper = document.body.querySelector( '.videos_wrapper' );
		this.#$videos = this.#$videosWrapper.querySelectorAll( '.video' );
		this.#$buttonNext = document.body.querySelector( '.button_next' );
		this.#$buttonVolume = document.body.querySelector( '.volume_toggle' );
		this.#$titles = document.body.querySelectorAll( '.video__title' );
		this.#$counters = document.body.querySelectorAll( '.counter_current' );
		this.#$players = document.querySelectorAll( '.video__player' );
	}

	initEl() {
		super.initEl();
		this.initSliders();
		this.updateDom();
	}

	bindEvents() {
		super.bindEvents();
		this.#$buttonNext.addEventListener( 'click', this.#next.bind( this ) );
		this.#$buttonVolume.addEventListener( 'click', this.#toggleVolume.bind( this ) );
		this.activateSoundHandler = this.#activateSound.bind( this );
		this.#activateSound();
	}

	initSliders() {
		this.#titlesSlider = new Slider( {
			root: this.#$titles[ 0 ].parentElement,
			wrapper: this.#$titles[ 0 ].parentElement,
			items: this.#$titles,
			drag: false,
			rotate: false,
			split: true,
			onChange: false,
		} );
		this.#countersSlider = new Slider( {
			root: this.#$counters[ 0 ].parentElement,
			wrapper: this.#$counters[ 0 ].parentElement,
			items: this.#$counters,
			drag: false,
			rotate: false,
			split: false,
			onChange: false,
		} );
		this.#videosSlider = new Slider( {
			root: this.#$videosWrapper,
			wrapper: this.#$videos[ 0 ].parentElement,
			items: this.#$videos,
			drag: true,
			rotate: true,
			split: false,
			onChange: this.onChange.bind( this ),
			onPause: this.onPause.bind( this ),
			onResume: this.onResume.bind( this ),
		} );
	}

	onChange( yPercent = 0, current = 0, delta = 0, source = '', pause = false ) {
		if ( this.#currentIndex !== current && gtag ) {
			gtag( 'event', 'video_display', {
				video_id: ( current + 1 ),
			} );
		}
		this.#currentIndex = current;
		this.#titlesSlider.update( yPercent );
		this.#countersSlider.update( yPercent );
		this.updateDom( pause );
		this.moveArrows( delta, source );
	}

	onPause() {
		this.#$videos.forEach( $video => $video.querySelector( 'video' ).pause() );
	}

	onResume() {
		this.#$videos.forEach( ( $video, index ) => {
			if ( index === this.#currentIndex ) {
				$video.querySelector( 'video' ).play();

			}
		} );
	}

	updateDom( pause = false ) {
		document.body.style.setProperty( '--color', window.colors[ this.#currentIndex ] );
		this.#$videos.forEach( ( $video, index ) => {
			if ( index === this.#currentIndex ) {
				$video.classList.add( 'current' );
				if ( ! pause ) {
					$video.querySelector( 'video' ).play();
				} else {
					$video.querySelector( 'video' ).pause();
				}
			} else {
				$video.classList.remove( 'current' );
				$video.querySelector( 'video' ).pause();
			}
		} );
	}

	#next() {
		this.#videosSlider.goto();
	}

	#activateSound() {
		if ( ! this.#volume ) {
			this.#toggleVolume();
		}
	}

	#toggleVolume() {
		this.#volume = ! this.#volume;
		this.#$buttonVolume.classList.toggle( 'on', this.#volume );
		this.#$players.forEach( ( $item, index ) => {
			$item.muted = ! this.#volume;
		} );
		if ( this.#volume ) {
			this.#audio.context.resume();
		} else {
			this.#audio.context.suspend();
		}
	}

	moveArrows( delta = 0, source = '' ) {
		if ( source !== 'wheel' ) {
			delta = -delta;
		}
		gsap.to( document.querySelectorAll( '.button_next svg.border, .counter svg' ), {
			duration: 0.3,
			ease: 'power1.out',
			rotate: delta > 0 ? '+=' + Math.round( Math.min( delta, 20 ) ) + 'deg' : '-=' + Math.round( Math.min( Math.abs( delta ), 20 ) ) + 'deg',
		} );
	}
};
