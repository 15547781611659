import '../css/styles.scss';
import '../sprite/logo.svg';
import '../sprite/separator.svg';
import '../sprite/icon-arrow.svg';
import '../sprite/icon-volume-off.svg';
import '../sprite/icon-volume-on.svg';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { Observer } from 'gsap/Observer';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { SplitText } from 'gsap/SplitText';
import Main from 'core/controllers/Main';
import Navigation from 'component/Navigation';
import Loader from 'component/Loader';

gsap.registerPlugin( Draggable );
gsap.registerPlugin( Observer );
gsap.registerPlugin( InertiaPlugin );
gsap.registerPlugin( SplitText );

const start = () => {
	Main.init();
	new Loader();
};

if ( document.readyState !== 'loading' ) {
	start();
} else {
	document.addEventListener( 'DOMContentLoaded', start );
}
