/**
 * Imports
 */
import CustomEvent from 'core/lib/CustomEvent';

/**
 * Abstract view
 * Many of the class that control dom elements extend this one
 */
export default class AbstractView extends CustomEvent {
	/**
	 * GSAP tweens
	 *
	 * @type {{}}
	 */
	gsap = {};

	constructor() {
		super();

		// Abstract class, prevent direct instanciation
		if ( this.constructor === AbstractView ) {
			throw new TypeError( 'Abstract class "AbstractView" cannot be instantiated directly' );
		}
	}

	/**
	 * Initialise everything
	 */
	init() {
		this.initDOM();
		this.initEl();
		this.bindEvents();
	}

	/**
	 * Step made to initialize the Dom variables
	 */
	initDOM() {
	}

	/**
	 * Step made to set behaviours
	 */
	initEl() {
	}

	/**
	 * Step made to create the events
	 *
	 */
	bindEvents() {
	}
}
