import Navigation from 'Navigation';

export default class Loader {
	constructor() {
		this.$loader = document.querySelector( '.main_loader' );
		this.$images = this.$loader.querySelectorAll( '.main_loader__image' );
		this.audio = null;
		this.audioContext = null;
		this.$videos = document.querySelectorAll( 'video' );
		this.$button = this.$loader.querySelector( '.main_loader__button' );
		this.$progress = this.$button.querySelector( '.progress' );
		this.max = this.$videos.length;
		this.loaded = 0;
		this.handleImages();
		this.checkAlreadyLoaded();
	}

	handleImages() {
		this.$images.forEach( ( $image, index ) => {
			const $img = $image.querySelector( 'img' );
			if ( $img.complete ) {
				$image.classList.add( 'loaded' );
			} else {
				$img.addEventListener( 'load', () => {
					$image.classList.add( 'loaded' );
				} );
			}
		} );
	}

	async startAudio() {
		this.audioContext = new AudioContext();
		this.audio = this.audioContext.createBufferSource();
		this.audio.buffer = await fetch( '/assets/video/background.mp3' )
			.then( res => res.arrayBuffer() )
			.then( arrayBuffer => this.audioContext.decodeAudioData( arrayBuffer ) );
		this.audio.connect( this.audioContext.destination );
		this.audio.loop = true;
		await this.audio.start();
		await this.audioContext.suspend();
	}

	checkAlreadyLoaded() {
		this.$videos.forEach( this.handleVideo.bind( this ) );
		this.updateLoader();
	}

	async handleVideo( $video ) {
		// $video.src = await this.preloadVideo( $video.src );
		this.updateLoader();
		$video.load();
		if ( $video.readyState === 4 ) {
			this.loaded++;
			this.updateLoader();
		} else {
			$video.addEventListener( 'canplaythrough', ( event ) => {
				this.loaded++;
				this.updateLoader();
			} );
		}
	}

	async preloadVideo( src ) {
		const res = await fetch( src );
		const blob = await res.blob();
		return URL.createObjectURL( blob );
	}

	updateLoader() {
		this.$progress.style.setProperty( '--progress', this.loaded / this.max );
		if ( this.loaded === this.max ) {
			this.$progress.parentElement.classList.add( 'complete' );
			this.$button.addEventListener( 'click', this.start.bind( this ) );
		}
	}

	async start() {
		if ( gtag ) {
			gtag( 'event', 'video_display', {
				video_id: 1,
			} );
			gtag( 'event', 'start', {
				click: 1,
			} );
		}
		await this.startAudio();
		new Navigation( { audio: this.audio, context: this.audioContext } );
		document.body.classList.add( 'loaded' );
	}
}
