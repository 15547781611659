/**
 * Imports
 */
import CustomEvent from 'lib/CustomEvent';
import Math_ from 'utils/Math';
import Device from 'controllers/Device';

/**
 * Screen manager
 */
export default new class Screen extends CustomEvent {
	/**
	 * Collection of custom events name
	 *
	 * @type {Object} Collection of custom events name
	 * @public
	 */
	E = {
		RESIZE: 'resize',
	};

	/**
	 * @type {number}
	 * @public
	 */
	DPR = window.devicePixelRatio || 1;

	/**
	 * @type {number}
	 * @public
	 */
	wW = 0; // window width

	/**
	 * @type {number}
	 * @public
	 */
	wH = 0; // window height

	/**
	 * @type {number}
	 * @public
	 */
	bW = 0; // body width

	/**
	 * @type {number}
	 * @public
	 */
	bH = 0; // body height

	/**
	 * @type {number}
	 * @public
	 */
	vw = 0; // vw
	/**
	 * @type {number}
	 * @public
	 */
	vh = 0; // vh

	/**
	 * @type {number}
	 * @public
	 */
	cX = 0; // center X

	/**
	 * @type {number}
	 * @public
	 */
	cY = 0; // center Y

	/**
	 * @type {number}
	 * @public
	 */
	vR = 0; // viewport ratio

	/**
	 * @type {number}
	 * @public
	 */
	hypo = 0; // screen hypotenuse

	/**
	 * @type {number}
	 * @public
	 */
	pbW = 0; // previous body width

	/**
	 * @type {boolean}
	 * @public
	 */
	isWindowFocused = true;

	/**
	 * Event handlers
	 *
	 * @type {{}}
	 * @private
	 */
	#eh = {};

	/**
	 * @type {boolean}
	 * @private
	 */
	#canResize = true;

	/**
	 * @type {boolean}
	 * @private
	 */
	#hasRequestedResize = false;

	/**
	 * @type {Object} Cash-dom or jQuery object
	 * @private
	 */
	#$window = null;

	/**
	 * @type {Object} Cash-dom or jQuery object
	 * @private
	 */
	#$body = null;

	/**
	 * @type {Object} Cash-dom or jQuery object
	 * @private
	 */
	#$controlHeight = null;

	/**
	 * @param {Object} $window Cash-dom or jQuery object
	 * @param {Object} $body   Cash-dom or jQuery object
	 * @public
	 */
	init() {
		this.#bindEvents();
		this.#processResize();
	}

	/**
	 * @private
	 */
	#bindEvents() {
		this.#eh.resize = this.#resize.bind( this );
		window.addEventListener( 'resize', this.#eh.resize );
		// this.eh.blur = this._windowOut.bind( this );
		// window.on( 'blur' , this.eh.blur );
		// this.eh.focus = this._windowIn.bind( this );
		// window.on( 'focus' , this.eh.focus );
	}

	/**
	 * @private
	 */
	#resize() {
		if ( this.#canResize ) {
			window.requestAnimationFrame( () => {
				this.#processResize();
				this.#canResize = false;
			} );

			setTimeout( () => {
				if ( this.#hasRequestedResize ) {
					this.#hasRequestedResize = false;
					this.#processResize();
				}
				this.#canResize = true;
			}, 300 );
		} else {
			this.#hasRequestedResize = true;
		}
	}

	/**
	 * @private
	 */
	#processResize() {
		this.#setResizeProps();

		const isMobileH = Device.IS_TOUCH && this.pbW === this.bW;

		this.emit( this.E.RESIZE, isMobileH );

		this.pbW = this.bW;
	}

	/**
	 * @private
	 */
	#setResizeProps() {
		this.wW = window.innerWidth;
		this.wH = window.outerHeight;
		this.bW = document.body.offsetWidth;
		this.bH = document.body.offsetHeight;
		this.cX = Math.round( this.bW / 2 );
		this.cY = Math.round( this.wH / 2 );
		this.vR = this.bW / this.wH;
		this.hypo = Math.ceil( Math_.getHypotenuse( this.bW, this.wH ) );

	}
};
