/**
 * Imports
 */
import gsap from 'gsap';
import CustomEvent from 'lib/CustomEvent';
import Device from 'controllers/Device';
import Screen from 'controllers/Screen';
import Utils from 'utils/Utils';

/**
 * The main class
 */
export default new class Main extends CustomEvent {
	/**
	 * Collection of custom events name
	 *
	 * @type {Object} Collection of custom events name
	 */
	E = {
		RAF: 'raf',
	};

	/**
	 * @type {number}
	 */
	INERTIA = 0.09;

	/**
	 * Returns a promise to make sure all is ready
	 *
	 * @return {Promise} Promise
	 */
	init() {
		this.#initEl();
		this.#bindEvents();
	}

	#initEl() {
		Device.init();
		Screen.init();
	}

	#bindEvents() {
		gsap.ticker.add( this.#raf.bind( this ) );
	}

	#raf( time, deltaTime, frame ) {
		this.emit( this.E.RAF, time, deltaTime, frame );
	}
};
