import AbstractView from 'core/abstracts/AbstractView';
import Main from 'core/controllers/Main';
import Screen from 'core/controllers/Screen';
import Math_ from 'core/utils/Math';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { Observer } from 'gsap/Observer';
import { SplitText } from 'gsap/SplitText';

export default class Slider extends AbstractView {
	/**
	 * @type {HTMLElement}
	 */
	#$root;
	/**
	 * @type {HTMLElement}
	 */
	#$wrapper;

	/**
	 * @type {NodeList}
	 */
	#$items;

	/**
	 * @type {Boolean}
	 */
	#drag = false;

	/**
	 * @type {Boolean}
	 */
	#rotate = false;

	/**
	 * @type {Boolean}
	 */
	#split = false;

	/**
	 * @type {function}
	 */
	#onChange = false;

	/**
	 * @type {function}
	 */
	#onPause = false;

	/**
	 * @type {function}
	 */
	#onResume = false;

	/**
	 * @type {HTMLElement}
	 */
	#$ghost;

	/**
	 * @type {Number}
	 */
	#y = 0;

	/**
	 * @type {Number}
	 */
	#delta = 0;

	/**
	 * @type {Number}
	 */
	#itemH = 0;

	/**
	 * @type {Number}
	 */
	#wrapperH = 0;

	/**
	 * @type {Number}
	 */
	#offsetH = 0;

	/**
	 * @type {Number}
	 */
	#max = 0;

	/**
	 * @type {Number}
	 */
	#half = 0;

	/**
	 * @type {Number}
	 */
	#currentIndex = 0;

	/**
	 * @type {Array}
	 */
	#splitTimelines = [];

	/**
	 * @type {Boolean}
	 */
	dirty = false;

	/**
	 * @type {boolean}
	 */
	#isPaused = true;

	/**
	 * @param options
	 * {
	 * 	root: {HTMLElement} The root element
	 * 	wrapper: {HTMLElement} The parent element
	 * 	items: {NodeList} The items to move
	 * 	drag: {boolean} If Draggable must be used
	 * 	rotate: {boolean} Apply rotate to the items
	 * 	split: {boolean} If SplitText must be used
	 *	onChange: {function} A function to call on update
	 *	onPause: {function} A function to call on update
	 *	onResume: {function} A function to call on update
	 * }
	 */
	constructor( options = {} ) {
		super();

		// $root, $wrapper, $items, drag = false
		this.#$root = options.root;
		this.#$wrapper = options.wrapper;
		this.#$items = options.items;
		this.#drag = options.drag;
		this.#rotate = options.rotate;
		this.#split = options.split;
		this.#onChange = options.onChange || false;
		this.#onPause = options.onPause || false;
		this.#onResume = options.onResume || false;
		this.#max = this.#$items.length - 1;
		this.#half = Math.round( this.#max * 0.5 );

		this.init();
	}

	update( y = 0 ) {
		this.#delta = y - this.#y;
		this.#moveItems( y * this.#itemH, 'external' );
	}

	goto( index = 1 ) {
		if ( this.#drag ) {
			this.draggable.disable();
		}
		const yStart = this.#y,
			temp = { y: yStart },
			yEnd = this.#snap( yStart - ( index * this.#itemH ) );
		if ( this.gsap.next ) {
			this.gsap.next.kill();
		}
		if ( ! this.#isPaused && this.#onPause ) {
			this.#isPaused = true;
			this.#onPause();
		}
		this.gsap.next = gsap.to( temp, {
			duration: 0.6,
			ease: 'power1.out',
			y: yEnd,
			onUpdate: () => {
				this.#delta = temp.y - yStart;
				this.#update( temp.y, 'goto' );
			},
			onComplete: () => {
				if ( this.#drag ) {
					this.draggable.enable();
					this.#y = temp.y;
					this.#updateDraggable();
				}
				if ( this.#isPaused && this.#onResume ) {
					this.#isPaused = true;
					this.#onResume();
				}
			},
		} );
	}

	initEl() {
		if ( this.#drag ) {
			this.#initDraggable();
			this.#initObserver();
		}
		if ( this.#split ) {
			this.#initSplit();
		}
		this.resize();
	}

	bindEvents() {
		Screen.on( Screen.E.RESIZE, this.resize, this );
	}

	resize( isMobileH = false ) {
		if ( isMobileH === true ) {
			return;
		}
		this.#itemH = this.#$items[ 0 ].offsetHeight;
		this.#wrapperH = this.#itemH * this.#$items.length;
		if ( this.#drag ) {
			this.draggable.kill();
			this.draggable.enable();
		}
		if ( this.#split ) {
			this.#splitTimelines.forEach( ( tl, index ) => {
				tl.progress( 1 );
			} );
		} else {
			this.#$items.forEach( ( $item, index ) => {
				gsap.set( $item, { yPercent: index * 100, rotate: 0 } );
			} );
		}
		this.#currentIndex = 0;
		this.#y = 0;
		this.#delta = 0;
		this.#moveItems( 0 );
		if ( this.#onChange ) {
			this.#onChange( 0, 0, 0, '', this.#onPause );
		}
	}

	#initDraggable() {
		this.#$ghost = document.createElement( 'div' );
		this.draggable = Draggable.create( this.#$ghost, {
			type: 'y',
			trigger: document.body,
			inertia: true,
			onDragStart: this.#onDragStart.bind( this ),
			onDrag: this.#onDrag.bind( this ),
			onThrowUpdate: this.#onDrag.bind( this ),
			onThrowComplete: this.#onDragEnd.bind( this ),
			snap: this.#snap.bind( this ),
		} )[ 0 ];
	}

	#initObserver() {
		this.observer = Observer.create( {
			target: window,
			type: 'wheel',
			wheelSpeed: 3,
			onStopDelay: 0.25,
			onWheel: this.#wheel.bind( this ),
			onStop: this.#stopWheel.bind( this ),
		} );
	}

	#initSplit() {
		this.#$items.forEach( ( $item, index ) => {
			const split = new SplitText( $item, { type: 'chars' } );
			const timeline = gsap.timeline( {
				paused: true,
			} );
			timeline.set( split.chars, {
				yPercent: -100, y: -10,
			}, 0 );
			timeline.to( split.chars, {
				yPercent: 0, y: 0, duration: 1, ease: 'power1.out', stagger: 0.04,
			} );
			timeline.to( split.chars, {
				yPercent: 100, y: 10, duration: 1, ease: 'power1.in', stagger: 0.04,
			} );
			this.#splitTimelines.push( timeline );
		} );
	}

	#snap( value ) {
		return gsap.utils.snap( this.#itemH, value );
	}

	#wheel( observerRef ) {
		if ( this.gsap.next ) {
			this.gsap.next.kill();
		}
		if ( this.draggable.isPressed || this.draggable.isThrowing ) {
			this.draggable.endDrag( observerRef.event );
			this.draggable.disable();
		}
		const startY = this.#y,
			temp = { y: startY };
		this.gsap.next = gsap.to( temp, {
			duration: 0.1,
			ease: 'none',
			y: startY - observerRef.deltaY,
			onUpdate: () => {
				this.#delta = observerRef.deltaY;
				this.#update( temp.y, 'wheel' );
			},
		} );
	}

	#stopWheel() {
		if ( this.#drag ) {
			this.draggable.enable();
			this.#updateDraggable();
		}
		this.goto( 0 );
	}

	#onDragStart() {
		if ( this.gsap.next ) {
			this.gsap.next.kill();
		}
		if ( ! this.#isPaused && this.#onPause ) {
			this.#isPaused = true;
			this.#onPause();
		}
	}

	#onDragEnd() {
		if ( this.#isPaused && this.#onResume ) {
			this.#isPaused = false;
			this.#onResume();
		}
	}

	#onDrag() {
		this.#delta = this.draggable.deltaY;
		this.#moveItems( this.draggable.y, 'drag' );
	}

	#update( y, source = '' ) {
		this.#moveItems( y, source );

	}

	#updateDraggable() {
		if ( ! this.#drag ) {
			return;
		}
		gsap.set( this.#$ghost, { y: this.#y } );
		this.draggable.update();
	}

	#moveItems( y, source = '' ) {

		// Makes sure the draggable y value stays in range
		const dragValue = this.#y = this.getY( y, this.#wrapperH ), currentIndex = this.getClosestIndex( dragValue ); // Calculate the current index from the draggable y value

		// Get the position percentage
		let posPercent = dragValue / this.#itemH;

		// Pos percent should be within -0.5 and 0.5
		// Adjust otherwise
		if ( Math.abs( Math.abs( currentIndex ) - Math.abs( posPercent ) ) > 0.5 ) {
			if ( Math.abs( currentIndex ) - Math.abs( posPercent ) < 0.5 ) {
				posPercent += ( this.#max + 1 );
			} else {
				posPercent -= ( this.#max + 1 );
			}
		}

		if ( currentIndex !== this.#currentIndex ) {
			this.#currentIndex = currentIndex;
		}

		if ( this.#onChange ) {
			this.#onChange( posPercent, currentIndex, this.#delta, source, this.#onPause );
		}

		// Loop through items
		this.#$items.forEach( ( $item, index ) => {

			let loopedIndex = index;
			for ( let i = 1; i <= 2; i++ ) {
				const offsetNext = this.getOffsetIndex( currentIndex, i ),
					offsetPrev = this.getOffsetIndex( currentIndex, -i );

				// NEXT GO TO THE START
				if ( index === offsetNext && currentIndex >= this.#max && index < currentIndex ) {
					loopedIndex = currentIndex + offsetNext + 1;
				}

				// PREVIOUS GO TO THE END
				if ( index === offsetPrev && currentIndex <= 2 && index > currentIndex ) {
					loopedIndex = index - offsetPrev - i;
				}
			}

			if ( this.#split ) {
				gsap.set( this.#splitTimelines[ index ], {
					progress: Math_.clamp( loopedIndex + posPercent, -0.5, 0.5 ) + 0.5,
				} );
			} else if ( this.#rotate && Screen.wW > 960 ) {
				gsap.set( $item, {
					yPercent: ( loopedIndex + posPercent ) * 100,
					rotate: ( loopedIndex + posPercent ) * 10,
					transformOrigin: '100% ' + ( loopedIndex + posPercent - 0.5 ) * -100 + '%',
				} );
			} else {
				gsap.set( $item, {
					yPercent: ( loopedIndex + posPercent ) * 100,
				} );
			}
		} );
	}

	getOffsetIndex( index, offset = 0 ) {
		return ( index + offset + this.#$items.length ) % this.#$items.length;
	}

	getClosestIndex( value, rounded = true ) {
		const absValue = Math.abs( value );
		// Calculate the raw index without considering looping
		let rawIndex = rounded ? Math.round( absValue / this.#itemH ) : absValue / this.#itemH;

		// Ensure the index is within the valid range
		return Math.abs( this.getOffsetIndex( rawIndex * ( value > 0 ? -1 : 1 ) ) );
	}

	getY( y ) {
		if ( y > -( this.#itemH * 2 ) ) {
			this.dirty = true;
			while ( y > -( this.#itemH * 2 ) ) {
				y -= this.#wrapperH;
			}
		} else if ( -y > ( this.#wrapperH * 2 ) - ( this.#itemH * 2 ) ) {
			this.dirty = true;
			while ( -y > ( this.#wrapperH * 2 ) - ( this.#itemH * 2 ) ) {
				y += this.#wrapperH;
			}
		}
		return y;
	}

	log() {
		if ( this.#drag ) {
			console.log.apply( null, arguments );
		}
	}

}
