/**
 * Device manager
 */
export default new class Device {
	/**
	 * @type {boolean}
	 */
	IS_TOUCH = false;

	init() {
		// Define the public propery IS_TOUCH by testing a css media
		this.IS_TOUCH = window.matchMedia( '(pointer: coarse)' ).matches;

		// Adds a css class to the html
		document.documentElement.classList.add( this.IS_TOUCH ? 'touch' : 'notouch' );
	}
};
