/**
 * Imports
 */
import EventEmitter from 'eventemitter3';

/**
 * Custom Event
 * A class made to handle custom events
 * Can only be extended
 *
 * @abstract
 */
export default class CustomEvent {
	/**
	 * EventEmitter instance
	 *
	 * @type {null|EventEmitter} EventEmitter instance
	 * @private
	 */
	e = null;

	/**
	 * Collection of custom events name
	 *
	 * @type {Object} Collection of custom events name
	 */
	E = {};

	constructor() {
		// Abstract class, prevent direct instanciation
		if ( this.constructor === CustomEvent ) {
			throw new TypeError( 'Abstract class "CustomEvent" cannot be instantiated directly' );
		}
	}

	/**
	 * Bind a custom event
	 *
	 * @param {string}   name    The name of the event
	 * @param {Function} fct     The callback function to call
	 * @param {Object}   context Optionnaly, a context to tie the callback
	 */
	on( name, fct, context ) {
		if ( this.e === null ) {
			// if the custom event doesn't exist, create it
			this.e = new EventEmitter();
		}

		this.e.on( name, fct, context ); // add the listener to the custom event
	}

	/**
	 * Bind a custom event only once
	 *
	 * @param {string}   name    The name of the event
	 * @param {Function} fct     The callback function to call
	 * @param {Object}   context Optionnaly, a context to tie the callback
	 */
	once( name, fct, context ) {
		if ( this.e === null ) {
			// if the custom event doesn't exist, create it
			this.e = new EventEmitter();
		}

		this.e.once( name, fct, context ); // add the listener to the custom event
	}

	/**
	 * Unbind a custom event
	 *
	 * @param {string}   name    The name of the event
	 * @param {Function} fct     The callback function previously called
	 * @param {Object}   context Optionnaly, the context tied to the callback
	 */
	off( name, fct, context ) {

		const nbEvents = this.e._events[ name ].length;

		this.e.removeListener( name, fct, context );
	}

	/**
	 * Emit a custom event
	 *
	 * @param {string} name   The name of the event
	 * @param {...any} params Additionnal optionnal parameters to pass to the callback
	 */
	emit( name, ...params ) {
		if ( this.e !== null ) {
			this.e.emit( name, ...params );
		}
	}
}

